

<template>
  <vx-card>
    <template>
      <!-- POP UP -->
      <vs-popup classContent="popup-example" title="เปลี่ยนสถานะ" :active.sync="popupActive">
        <vs-select style="margin:10px;" class="status-to-return" v-model="statusToReturn">
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in statusToReturnOption"
            style="width:100%; font-size: 12px !important;"
          />
        </vs-select>
        <vs-button
          @click="popupActive=false"
          style="margin:10px;"
          color="success"
          type="filled"
        >ตกลง</vs-button>
        <vs-button
          @click="popupActive=false"
          style="margin:10px;"
          color="danger"
          type="filled"
        >ยกเลิก</vs-button>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- HEADER -->
    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="6"
      >วันที่คืนชุด</vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="6">
        <datepicker
          style="width:100%;"
          placeholder="วันที่คืนชุด"
          format="yyyy-MM-dd"
          v-model="searchDate"
        ></datepicker>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="12">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
          >
            <vs-button style="width:100%;" color="primary" type="filled">ค้นหา</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
          >
            <vs-button style="width:100%;" color="warning" type="filled">reset</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
          >
            <!-- <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0; "
              color="rgb(255, 114, 131)"
              type="filled"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                >print</i>
              </span>
              <span style=" vertical-align:middle;">พิมพ์ใบเสร็จ</span>
            </vs-button>-->
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <!-- BRANCHES -->
    <vs-row vs-w="12">
      <ul class="demo-alignment">
        <li v-for="(b, index) in branches" :key="index">
          <vs-checkbox v-model="branchCheckbox" :vs-value="b">{{ b }}</vs-checkbox>
        </li>
      </ul>
    </vs-row>
    <!-- BRANCHES -->
    <!-- HEADER -->

    <!-- TABLE -->
    <vs-table style="z-index:1;" pagination max-items="20" search :data="orders">
      <template style="z-index:1 !important;" slot="thead">
        <vs-th sort-key="incharge">ลงรายการ</vs-th>
        <vs-th sort-key="orderNumber">เลข Order</vs-th>
        <!-- <vs-th sort-key="productItem">ชื่อสินค้า</vs-th> -->
        <vs-th sort-key="customerName">ข้อมูลชื่อผู้เช่า</vs-th>
        <vs-th sort-key="rentalTotal">ยอดเช่าชุด</vs-th>
        <vs-th sort-key="bail">เงินประกัน</vs-th>
        <vs-th sort-key="pickupDate">วันรับชุด</vs-th>
        <vs-th sort-key="returnDate">วันคืนชุด</vs-th>
        <!-- <vs-th sort-key="orderStatus">สถานะ</vs-th> -->
        <vs-th>
          สถานะ
          <br />การคืนเงิน
        </vs-th>
        <vs-th>คืนสถานะ</vs-th>
        <vs-th>การจัดการ</vs-th>
      </template>

      <template style="z-index:1 !important;" slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td style="width:100px;">
            {{tr.updateBy}}
            <br />
            {{tr.updateDate}}
          </vs-td>
          <vs-td style="text-align:center; max-width:90px;">
            <span style="color: #2372CE; font-weight:bold;">{{tr.orderNumber}}</span>
            <br />
            {{tr.branch}}
            <br />
          </vs-td>
          <!-- <vs-td>ข้อมูลสินค้า</vs-td> -->
          <vs-td style="max-width:120px; font-family: SukhumvitSet-Medium;">
            {{tr.customerName}}
            <br />
            {{tr.customerAddress}}
          </vs-td>

          <vs-td style="max-width:60px; padding-left:20px;">{{tr.rentalTotal}}</vs-td>
          <vs-td style="max-width:60px; padding-left:20px;">{{tr.bail}}</vs-td>

          <vs-td>{{tr.pickupDate}}</vs-td>
          <vs-td>{{tr.returnDate}}</vs-td>
          <!-- <vs-td>{{tr.orderStatus}}</vs-td> -->
          <vs-td style="max-width:60px !important;">
            <span
              v-if="tr.bailReturningStatus === 'โอนแล้ว'"
              class="returningBail"
            >{{tr.bailReturningStatus}}</span>
            <span v-else class="NotReturn">{{tr.bailReturningStatus}}</span>
            <span
              v-if="tr.bailReturningStatus === 'โอนแล้ว'"
              class="bailReturningDate"
            >{{tr.bailReturningDate}}</span>
          </vs-td>
          <vs-td style="text-align: center;">
            <i
              @click="$event.stopPropagation()"
              style="font-size:30px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; color:#5A8EE6 "
              class="material-icons"
            >reply_all</i>
          </vs-td>
          <vs-td>
            <i
              @click="$event.stopPropagation()"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons"
            >speaker_notes</i>
            <i
              @click="$event.stopPropagation()"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons"
            >monetization_on</i>
          </vs-td>
          <div style="width: 100%; background-color:pink; height:50px; "></div>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TABLE -->

    <br />
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
// window.addEventListener("load", function() {
//   var x = document.getElementsByClassName("tr-expand ");
//   for (var ii = 0; ii < x.length; ii++) {
//     x[ii].innerHTML = "12";
//   }
// });
export default {
  data() {
    return {
      branchCheckbox: ["ลาดพร้าว", "เมืองทองธานี"],
      branches: ["ลาดพร้าว", "เมืองทองธานี"],

      statusToReturn: "คืนชุดแล้ว",
      statusToReturnOption: [
        { text: "คืนชุดแล้ว", value: "คืนชุดแล้ว" },
        { text: "ยกเลิก", value: "ยกเลิก" }
      ],
      popupActive: false,
      searchDate: "",
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: "เงินสด",
      payment_option: [
        { text: "เงินสด", value: "เงินสด" },
        { text: "โอนเงิน", value: "โอนเงิน" },
        { text: "Alipay/wechat", value: "Alipay/wechat" },
        { text: "บัตรเครดิต", value: "บัตรเครดิต" }
      ],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: [],
      orders: [
        {
          selected: false,
          orderNumber: "OR190312009",
          branch: "เมืองทองธานี",
          customerName: "Leanne Graham",
          customerAddress: "199/14 ถนนเสรีไทย เขตบางบอน กทม",
          rentalTotal: 1300,
          bail: 1200,
          pickupDate: "2019-04-13",
          returnDate: "2019-04-20",
          paymentMethod: "เงินสด",
          bailReturningStatus: "โอนแล้ว",
          bailReturningDate: "2019-04-20 23:34:56",
          updateBy: "ลิลลี่",
          updateDate: "2019-04-12",
          items: ["CDF014S", "CWF017XL", "3cff163f"]
        },
        {
          selected: false,
          orderNumber: "OR190312008",
          branch: "เมืองทองธานี",
          customerName: "Leanne Graham",
          customerAddress: "199/14 ถนนเสรีไทย",
          rentalTotal: 1300,
          bail: 1500,
          pickupDate: "2019-04-13",
          returnDate: "2019-04-20",
          paymentMethod: "โอนเงิน",
          bailReturningStatus: "โอนแล้ว",
          bailReturningDate: "2019-04-20 23:34:56",
          updateBy: "พี่โจ",
          updateDate: "2019-04-12",
          items: ["CDF014S", "CWF017XL", "3cff163f"]
        },
        {
          selected: false,
          orderNumber: "OR190312007",
          branch: "ลาดพร้าว",
          customerName: "Leanne Graham",
          customerAddress: "199/14 ถนนเสรีไทย",
          rentalTotal: 1500,
          bail: 1200,
          pickupDate: "2019-04-13",
          returnDate: "2019-04-20",
          paymentMethod: "บัตรเครดิต",
          bailReturningStatus: "ยังไม่โอน",
          bailReturningDate: "",
          updateBy: "พี่โจ",
          updateDate: "2019-04-12",
          items: ["CDF014S", "CWF017XL"]
        },
        {
          selected: false,
          orderNumber: "OR190312009",
          branch: "เมืองทองธานี",
          customerName: "Leanne Graham",
          customerAddress: "199/14 ถนนเสรีไทย เขตบางบอน กทม",
          rentalTotal: 2400,
          bail: 2000,
          pickupDate: "2019-04-13",
          returnDate: "2019-04-20",
          paymentMethod: "เงินสด",
          bailReturningStatus: "ยังไม่โอน",
          bailReturningDate: "",
          updateBy: "พี่โจ",
          updateDate: "2019-04-12",
          items: ["CDF014S", "CWF017XL", "3cff163f"]
        },
        {
          selected: false,
          orderNumber: "OR190312008",
          branch: "เมืองทองธานี",
          customerName: "Leanne Graham",
          customerAddress: "199/14 ถนนเสรีไทย",
          rentalTotal: 1300,
          bail: 1200,
          pickupDate: "2019-04-13",
          returnDate: "2019-04-20",
          paymentMethod: "โอนเงิน",
          bailReturningStatus: "ยังไม่โอน",
          bailReturningDate: "",
          updateBy: "พี่โจ",
          updateDate: "2019-04-12",
          items: ["CDF014S", "CWF017XL", "3cff163f"]
        }
      ]
    };
  },
  created: function() {
    this.originalList = this.orders;
  },

  components: {
    Prism,
    Datepicker
  },
  watch: {
    selectedList: function() {
      var ordersLength = this.orders.length;
      var selectedLength = this.selectedList.length;

      if (ordersLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN ordersLength == undefined");

        // console.log("SelectAll");
      }
      if (selectedLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN selectedLength == undefined)");
      }

      if (ordersLength == selectedLength && selectedLength > 0) {
        this.selectStatus = 1;
        if (this.fromSelectAll === false) this.selectAll = true;
        // console.log("IN ordersLength == selectedLength && selectedLength > 0");
        // console.log("SelectAll");
      } else {
        if (selectedLength == 0 || selectedLength == undefined) {
          this.selectStatus = 0;
          if (this.fromSelectAll === false) this.selectAll = false;
          // console.log("IN selectedLength == 0 || selectedLength == undefined");
        } else {
          this.selectStatus = 2;
          if (this.fromSelectAll === false) this.selectAll = true;
          // console.log("IN ELSE");
          // console.log("Select some");
        }
      }
      // console.log(this.orders);
      this.fromSelectAll = false;
      // console.log(
      //   "WACTCH selectedList >>> this.selectAll : " +
      //     this.selectAll +
      //     "  >>> this.selectStatus :  " +
      //     this.selectStatus
      // );

      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    branchCheckbox: function() {
      this.orders = [];

      if (this.branchCheckbox.length >= 1) {
        for (var i = 0; i < this.originalList.length; i++) {
          for (var j = 0; j < this.branchCheckbox.length; j++) {
            if (this.originalList[i].branch == this.branchCheckbox[j]) {
              this.orders.push(this.originalList[i]);
            }
          }
        }
      }

      //   console.log("AFTER WORK ORIGINAL >>> ");
      //   console.log(this.originalList);
      //   console.log("AFTER WORK ORDERS >>> ");
      //   console.log(this.orders);
      // }
    }
  },

  methods: {
    changeToReturn: function(event) {
      event.stopPropagation();
      this.popupActive = true;
    },
    selectAllOrders: function(event) {
      event.stopPropagation();

      var ind = 0;

      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0 || this.selectStatus === 2) {
        // console.log("expected >>> 0 or 2");
        this.selectedList = [];
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = true;
          this.selectedList.push(this.orders[ind]);
        }
        this.selectStatus = 1;
      } else if (this.selectStatus === 1) {
        // console.log("expected >>> 1");
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = false;
        }
        this.selectedList = [];
        this.selectStatus = 0;
      }
      this.fromSelectAll = true;
      // console.log(
      //   "SelectAll ==> " + this.selectAll + " STATUS  ===> " + this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    addSelectedList(indextt, event) {
      event.stopPropagation();
      // console.log(this.orders[indextt].selected);

      if (!this.orders[indextt].selected) {
        this.orders[indextt].selected = true;
        this.selectedList.push(this.orders[indextt]);
        // console.log("ADDED");
      } else {
        if (this.selectedList) {
          this.orders[indextt].selected = false;
          var foundIndextt = this.selectedList.findIndex(
            x => x.orderNumber === this.orders[indextt].orderNumber
          );
          this.selectedList.splice(foundIndextt, 1);
          // console.log("REMOVED");
        }
      }
      this.orders[indextt].selected = !this.orders[indextt].selected;
      // console.log(
      //   "SelectAll ==> " +
      //     this.selectAll +
      //     "TRUE STATUS  ===> " +
      //     this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    formatPrice(value) {
      // let val = (value / 1).toFixed(2).replace(",", ".");
      console.log(value);

      // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>

<style>
.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.material-icons:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}

.returningBail {
  font-family: SukhumvitSet-Bold;
  font-size: 14px;
  color: #7ed321;
  letter-spacing: 0;
  text-align: center;
}

.NotReturn {
  font-family: SukhumvitSet-Bold;
  font-size: 14px;
  color: #9b9b9b;
  letter-spacing: 0;
}

.bailReturningDate {
  font-family: SukhumvitSet-Bold;
  font-size: 9px;
  color: #4a90e2;
  letter-spacing: 0;
  text-align: center;
}
.form-label {
  font-weight: bold;
}

.imgclass .aura-btn {
  text-align: center;
  margin-left: 20px;
  margin-top: 90px;
  visibility: hidden;
  position: absolute;
  width: 90px;
  height: 30px;
  border: #4c4c4c 1px solid;
  color: white;
  cursor: pointer;
  background-color: #e89999b5;
}

.imgclass .setmain-btn {
  text-align: center;
  margin-left: 20px;
  margin-top: 50px;
  visibility: hidden;
  position: absolute;
  width: 80px;
  height: 30px;
  border: #4c4c4c 1px solid;
  color: black;
  cursor: pointer;
  background-color: #ffffffb5;
}

.imgclass:hover .aura-btn {
  visibility: visible;
}
</style>
